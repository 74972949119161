.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.popup-container {
  position: fixed;
  z-index: 5;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.popup-body {
  position: fixed;
  left: 5%;
  right: 5%;
  bottom: 15%;
  top: 15%;
  text-align: center;
  margin: auto;
  border-radius: 15px;
  border-color: black;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.confirmBtn {
  margin-right: 15px;
}

#importForm .MuiTableCell-paddingCheckbox {
  display: none;
}

.error {
  color: red;
  font-size: 18px;
}

/* .container.importGrid {
  background-color: yellow;
  grid-template-columns: 20fr 60fr 20fr;
} */
.importGrid {
  border-collapse: collapse;
  border: 1px red;
}

.importGrid .name {
  max-width: 300px;
}

.importGrid .description {
  max-width: 300px;
}

table,
th,
td {
  border: 1px solid black;
}

.importGrid td {
  padding: 5px;
  /* background-color: white; */
  border-bottom: 1px solid gray;
  vertical-align: top;
}

.importGrid .terms {
  overflow: hidden;
  max-width: 200px;
}

.importGrid .new {
  color: red;
}

.importGrid .existing {
  color: green;
}

.importForm .saveButton {
  max-height: 45px;
  margin-top: 30px;
  min-height: 30px;
  padding: 10px 20px;
  font-size: 18px;
  border-width: 1px;
  border-style: solid;
}
